import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutContent from "@/views/LayoutContent";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LayoutContent
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/aboutCompany')
  },
  {
    path: '/about/info',
    name: 'info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/aboutCompany/info')
  },
  {
    path: '/link',
    name: 'link',

    component: () => import(/* webpackChunkName: "about" */ '@/views/linkCompany')
  },
  {
    path: '/productDetail',
    name: 'productDetail',

    component: () => import(/* webpackChunkName: "about" */ '@/views/product')
  },
  {
    path: '/cases',
    name: 'cases',

    component: () => import(/* webpackChunkName: "about" */ '@/views/cases')
  },
  {
    path: '/news',
    name: 'news',

    component: () => import(/* webpackChunkName: "about" */ '@/views/news')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',

    component: () => import(/* webpackChunkName: "about" */ '@/views/news/detail')
  }
]

const router = new VueRouter({

  base: process.env.BASE_URL,
  routes
})

export default router
