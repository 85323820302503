<template>
  <div class="layout-content-container">
    <div class="home-page-banner">
        <first-carousel></first-carousel>
        <industry-intro></industry-intro>
        <technology-banner></technology-banner>
        <technology-container></technology-container>
        <solution-block></solution-block>
        <dynamic-container></dynamic-container>
        <foot-box></foot-box>
    </div>
  </div>
</template>

<script>
import FirstCarousel from "@/views/body/FirstCarousel";
import IndustryIntro from "@/views/body/IndustryIntro";
import TechnologyBanner from "@/views/body/TechnologyBanner";
import TechnologyContainer from "@/views/body/TechnologyContainer";
import SolutionBlock from "@/views/body/SolutionBlock";
import DynamicContainer from "@/views/body/DynamicContainer";
import footBox from "@/views/FootBox";
export default {
  name: "LayoutContent",
  components: {
    FirstCarousel,
    IndustryIntro,
    TechnologyBanner,
    TechnologyContainer,
    SolutionBlock,
    DynamicContainer,
    footBox
  }
}
</script>

<style scoped>
  .layout-content-container {
    padding-top: 78px;
    transition: all .3s ease-in-out;
  }
  .home-page-banner {
    position: relative;
    min-width: 1280px;
    max-width: 2048px;
    margin: 0 auto;

  }
  @media (max-width: 768px) {
    .home-page-banner {
      min-width: auto;
    }
  }
</style>
