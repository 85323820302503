<template>
  <div class="footer fullscreen-container">
    <div class="footer-bottom min-container"  >
      <div class="copy-righ" ><span
      >Copyright © 2022 济南上明能源科技有限公司  </span> <a
           class="footer-link-button record"
           href="https://beian.miit.gov.cn/" target="_blank"
      >鲁ICP备2022028860号</a>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "FootBox"
}
</script>

<style scoped>
  .footer {
    background: #f9f9f9;
  }
  .footer-bottom {
    padding: 20px 0;
    border-top: 1px solid #eaeaea;
    display: flex;
  }
  .copy-righ {
    color: #545454;
  }
  .record {
    margin-left: 12px;
  }
  .footer-link-button {
    color: #545454;
    line-height: 24px;
  }
  @media (max-width: 768px) {
    .footer {
      padding: 0 24px;
    }
    .footer-bottom {
      padding-right: 24px;
      padding-left: 24px;
      text-align: center;
      flex-direction: column;
    }
    .copy-right {
      flex-direction: column;
      font-size: 12px;
      line-height: 20px;
      margin: 4px 0 24px;
    }
    .record {
      margin: 0;
    }
    .footer-link-button {
      font-size: 12px;
      line-height: 20px;
    }
  }
</style>
