<template>
  <div class="dynamic-container-bg fullscreen-container">
    <div class="dynamic-container min-container"><h2 class="title">新闻及活动</h2>
      <div class="mark-bar-content mark-bar bar-primary"></div>
     <DynamicContainerNews></DynamicContainerNews>
      <a @click="toNews" class="btn learn-more is-outlined-primary hidden-mobile" tabindex="0" target="_self"
         id="dynamicBlockLearnMoreBtn"><!----> <span>查看更多</span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="btn-icon">
          <path d="M2 9.49999L13.5 9.5L10.5 5.00001" stroke="currentColor" stroke-linecap="square"></path>
        </svg>
      </a></div>
  </div>
</template>

<script>
import DynamicContainerNews from "@/views/body/DynamicContainerNews";
export default {
  name: "DynamicContainer",
  components:{
    DynamicContainerNews,
  },
  methods:{
    toNews: function (){
      this.$router.push({
        path: '/news'
      })
    }
  }
}
</script>

<style scoped>
.dynamic-container-bg {
  padding-top: 94px;
  background-color: #f9f9f9;
}
.fullscreen-container {
  margin: 0 auto;
}
.dynamic-container {
  text-align: center;
}
.min-container {
  width: 1280px;
}
.title {
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  width: 100%;
  color: #131313;
  font-weight: 800;
  font-size: 36px;
  line-height: 54px;
}
/*mark-bar*/
.mark-bar {
  margin-top: 16px;
}
.bar-primary {
  border-top-color: #e60012;
}
.mark-bar-content {
  border-top-width: 8px;
  border-top-style: solid;
  border-right: 6px solid rgba(0,0,0,0);
  width: 52px;
  height: 0;
}
btn {
  padding: 17px 38px;
  background-color: rgba(0,0,0,0);
  border: 2px solid rgba(0,0,0,0);
  cursor: pointer;
}
.learn-more {
  align-self: center;
  margin-bottom: 84px;
}
.btn.is-outlined-primary {
  color: #000;
  background-color: rgba(0,0,0,0);
  border-color: #bfbfbf;
}
@media (max-width: 768px) {
  .min-container {
    width: auto;
  }
  .dynamic-container-bg {
    padding-top: 72px;
    padding-bottom: 52px;
  }
  .fullscreen-container {
    min-width: auto;
  }
  .title {
    font-size: 28px;
    line-height: 44px;
    text-align: center
  }
   .mark-bar {
    margin: 12px auto 0;
  }
}
</style>
