<template>
  <div id="app">
    <Head ></Head>
    <router-view  :key="$route.fullPath" />
  </div>
</template>
<script>
import Head from "@/views/Head";
export default {
  name: 'App',
  components: {
    Head,

  },
  data: function () {
    return {
      showVal: false
    }
  },
  methods: {
    showPopup() {
      this.showVal = true
    },
  },
  mounted() {

  }
}
</script>
<style>

body {
  margin: 0;
  background-color: #fff;
  text-align: left;
}

img {
  border-style: none;
  vertical-align: middle;
}

</style>


