<template>
  <div>
    <div class="dropdown-container" :class="{'dropdown-opened':showDropdown}">
      <div class="mobile-dropdown-container">
        <div class="toast-container">
          <div class="toast toast default" style="display:none"></div>
        </div>
        <div>
          <ul class="list-unstyled">
            <li v-for="(item, i) in headerInfo" class="item" @click="showList(i)"><span
                class="title">{{ item.name }} </span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="arrow">
                <path d="M2 9.49999L13.5 9.5L10.5 5.00001" stroke="currentColor" stroke-linecap="square"></path>
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="dropdown-container" :class="{'dropdown-opened':showListVal}">
      <div style="padding:24px;">
        <div class="return-home">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
               xmlns="http://www.w3.org/2000/svg" class="return-button">
            <path d="M14 9.49999L2.5 9.5L5.5 5.00001" stroke="currentColor" stroke-linecap="square"></path>
          </svg>
          <span class="return-text" @click="showListVal=!showListVal">返回主菜单</span></div>
        <div class="secondary-title">{{showHeaderInfoDetail.title}}</div>
        <ul class="list-unstyled">
          <li class="nav-item" >
            <div class="dropdown-toggle" v-for="item in showHeaderInfoDetail.items">
                <a
                    @click="linkTo(item.link)"
                   class="third-title active">
                  {{item.name}}
                </a>
              <!----></div>
<!--            <div  class="dropdown-menu-container show">-->
<!--              <div class="dropdown-menu">-->
<!--                <a href="/cn/products/silicon/p-type/"-->
<!--                                            class="dropdown-item">P 型硅片</a><a-->
<!--                  href="/cn/products/silicon/n-type/"-->
<!--                  class="dropdown-item">N-->
<!--                型硅片</a></div>-->
<!--            </div>-->
          </li>

        </ul>
<!--                <div class="navbar-collapse">-->
<!--                  <van-collapse v-model="activeNames" ref="collapse">-->
<!--                    <van-collapse-item title="标题1" name="1">-->
<!--                      <div class="dropdown-menu"><a-->
<!--                          href="/cn/solutions/detail/eco-friendly-solar/"-->
<!--                          class="dropdown-item">友好型电站方案</a><a-->
<!--                          href="/cn/solutions/detail/solar-plus-energy-storage/"-->
<!--                          class="dropdown-item">源网荷储方案</a><a-->
<!--                          href="/cn/solutions/detail/hybrid-power-plants/"-->
<!--                          class="dropdown-item">多能互补方案</a></div>-->
<!--                    </van-collapse-item>-->
<!--                    <van-collapse-item title="标题2" name="2">-->
<!--                      技术无非就是那些开发它的人的共同灵魂。-->
<!--                    </van-collapse-item>-->
<!--                    <van-collapse-item title="标题3" name="3">-->
<!--                      在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。-->
<!--                    </van-collapse-item>-->
<!--                  </van-collapse>-->
<!--                </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import * as productSever from "@/api/product/index";

export default {
  name: "HeadMobileTap",
  props: ['showDropdown'],
  data: function () {
    return {
      showListVal: false,
      activeNames: [1],
      headerInfo: [
        {
          name: '产品',
        },
        {
          name: '新闻',
        },
        {
          name: '成功案例',
        },
        {
          name: '关于上明',
        },
      ],
      headerInfoDetail: [
        {
          title: '单晶硅片',
          items: [
            {
              name: '单晶144片182组件',
              link: 'productDetail'
            },
            {
              name: '单晶硅132片182组件',
              link: 'productDetail'
            },

          ]
        },
        {
          title: '新闻',
          items: [
            {
              name: '上明新闻',
              link:'/news'
            },

          ]
        },
        {
          title: '成功案例',
          items: [
            {
              name: '成功案例',
              link:'/cases'
            },

          ]
        },
        {
          title: '关于上明',
          items: [
            {
              name: '公司简介',
              link:'/about'
            },
            {
              name: '公司资质',
              link:'/about/info'
            },
            {
              name: '联系方式',
              link:'/link'
            },

          ]
        }

      ],
      showHeaderInfoDetail:{},
    }
  },
  methods: {
    linkTo(path){
      this.$router.push(path)
      this.$emit('changeShowListVal', false)
    },
    showList: function (i) {
      this.showListVal = !this.showListVal
      this.showHeaderInfoDetail = this.headerInfoDetail[i]

    },
    getProduct() {
      let data = {
        pageNo: 1,
        pageSize: 10
      }
      productSever.getSelectByPage(data).then(e=>{
        if (e.status == 200) {
          let data = e.data.data.records
          let arr = []
          for(let item of data){
            let obj = {
              name: item.title,
              link:'productDetail?id='+item.id
            }
            arr.push(obj)
          }
          console.log('arr', arr)
          this.headerInfoDetail[0].items = arr
        }
      })
    },
  },
  mounted() {
    this.getProduct()
    // this.$refs.collapse.value.toggleAll(true);
    // console.log(this.$refs.collapse)
  },
  watch: {
    showDropdown: function (val) {
      if (!val) {
        this.showListVal = false
      }
    },


  }
}
</script>

<style scoped>
.toast-container {
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  padding: 0 24px;
  position: fixed;
  top: 50px;
  width: 100%;
}

.default {
  background-color: #e4f0fb;
}

.toast {
  padding: 12px 24px;
  text-align: center;
}

.item {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.title {
  margin-right: 32px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.arrow {
  width: 16px;
  height: 24px;
}

@media (max-width: 768px) {
  .dropdown-container {
    position: fixed;
    top: 68px;
    bottom: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    background-color: #f9f9f9;
    left: 100%;
  }

  .dropdown-container.dropdown-opened {
    left: 0;
  }

  .mobile-dropdown-container {
    right: 0;
    left: 0;
    padding-right: 24px;
    padding-left: 24px;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: scroll;
  }

  .return-home {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 24px;
  }

  .return-button {
    margin-right: 6px;
    width: 16px;
    height: 16px

  }

  .return-text {
    font-size: 14px;
    line-height: 24px;
  }

  .secondary-title {
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 16px;
  }

  .dropdown-menu {
    display: flex;
    flex-direction: column;
  }

  .dropdown-item:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }

  .dropdown-item {
    margin-left: 12px;
    padding: 12px 0;
    color: #545454;
    font-size: 14px;
    line-height: 24px;
  }
  .dropdown-toggle {
    padding: 16px 0;
    border-top: 1px solid #eaeaea;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .third-title {
    margin-right: 32px;
    flex: 1 1;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
  }
  .dropdown-menu-container {
    border-top: 1px solid #eaeaea;
  }
  .dropdown-menu {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

</style>
