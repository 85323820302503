<template>
  <div>
    <div class="technology-banner fullscreen-container">
      <picture class="bg-image" data-v-1ea25e94="">
        <source media="(max-width: 768px)"
               >
        <img  alt=""
             style="object-fit:cover;object-position:center;font-family:'object-fit: cover; object-position: center;'"
             class="" src="@/assets/bg2.jpg">
      </picture>
      <div class="tech-container">
        <h2 class="tech-title">技术创新</h2>
        <div class="mark-bar-content white-bar bar-normal">
        </div>
<!--        <el-button class="is-dark-background">进入创新实验室</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechnologyBanner"
}
</script>

<style scoped>
  .technology-banner {
    position: relative;
    width: 100%;
    padding-bottom: 74px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  .bg-image {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .tech-container {
    margin: auto;
    padding-top: 64px;
    width: 1280px;
    color: #fff;
  }
  .tech-title {
    padding-bottom: 16px;
    width: 368px;
    font-weight: 800;
    font-size: 36px;
    line-height: 54px;
  }
  .bar-normal {
    border-top-color: #fff;
  }
  .mark-bar-content {
    border-top-width: 8px;
    border-top-style: solid;
    border-right: 6px solid rgba(0,0,0,0);
    width: 52px;
    height: 0;
  }
  .white-bar {
    padding-bottom: 52px;
  }
  .fullscreen-container {
    max-width: 2048px;
  }
  @media (max-width: 768px) {
    .fullscreen-container {
      min-width: auto;
    }
    .tech-container {
      text-align: center;
      margin: auto;
      padding-top: 64px;
      width: 100%;
    }
    .tech-title {
      margin: 0 24px;
      text-align: center;
      width: auto;
      font-weight: 800;
      font-size: 28px;
      line-height: 44px;
    }
    .white-bar {
      margin: auto;
    }
  }

</style>
