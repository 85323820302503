<template>
  <div class="grey-bg fullscreen-container">
    <div class="solution">
      <div class="introduction"><h2 class="title">解决方案</h2>
        <div class="mark-bar-content title-divide-line bar-primary"></div>
        <p class="text">
          上明正走在—条从产品创新改变行业到技术创新改变世界能源格局的创变之路。面对各行各业对绿色能源的多场景应用需求，上明基于自身产品及方案优势，不断丰富全场景能源方案。</p>
      </div>
      <solution-list :caseList='caseList'></solution-list>
      <solution-list-mobile :caseList='caseList'></solution-list-mobile>
    </div>
  </div>
</template>

<script>
import SolutionList from "@/views/body/SolutionList";
import SolutionListMobile from "@/views/body/SolutionListMobile";
import * as caseService from "@/api/cases";
export default {
  name: "SolutionBlock",
  components:{
    SolutionList,
    SolutionListMobile,
  },
  data: function () {
    return {
      caseList: [],
      pagination: {
        current: 1,
        pageSize: 4,
        total: 100,
        end: false,
      },
    }
  },
  methods: {
    getSearchData() {
      let data = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }
      return data
    },
    loadData() {

      let data = this.getSearchData()
      caseService.getSelectByPage(data).then(e => {
        if (e.status == 200) {
          let data = e.data.data
          this.pagination.total = data.total
          this.pagination.pageSize = data.size
          this.pagination.current = data.current
          this.caseList = data.records
        } else {
          console.log('查询失败')
        }

      }).finally(() => {
      })
    }
  },
  created() {
    this.loadData()

  }
}
</script>

<style scoped>
.grey-bg {
  margin: 0 auto;
  position: relative;
}
.grey-bg:before {
  background-color: #f9f9f9;
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 96px;
  content: "";
}
.solution {
  display: flex;
  flex-direction: column;
  width: 1280px;
  margin: 0 auto;
  padding-top: 66px;
}
.introduction {
  margin-bottom: 42px;
  width: 1280px;
}
.title {
  color: #131313;
  font-weight: 800;
  font-size: 36px;
  line-height: 54px;
}
.title-divide-line {
  margin-top: 16px;
}
.bar-primary {
  border-top-color: #e60012;
}
.mark-bar-content {
  border-top-width: 8px;
  border-top-style: solid;
  border-right: 6px solid rgba(0,0,0,0);
  width: 52px;
  height: 0;
}
.text {
  width: 700px;
  color: #545454;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
}
@media (max-width: 768px) {
  .fullscreen-container {
    min-width: auto;
  }
  .grey-bg:before {
    background-color: rgba(0,0,0,0);
  }
  .solution {
    width: 100%;
    min-width: 360px;
  }
  .introduction {
    width: auto;
  }
  .title {
    margin: 0 24px 12px;
    text-align: center;
    font-size: 28px;
    line-height: 44px;
  }
  .title-divide-line {
    margin: 0 auto;
  }
  .text {
    margin: 24px 24px 0;
    text-align: center;
    width: auto;
  }
}
</style>
