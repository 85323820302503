export const ALERT_TIME = 2000
export const MESSAGE_CAN_NOT_CONNECT = '无法连接'

/**
 * 获取头信息
 */
export function getHeader () {
  return {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
}
/**
 * FormData
 */
export function getMultipartHeader () {
  return {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
}
/**
 * 拼接对象为请求字符串
 * @param {Object} obj - 待拼接的对象
 * @returns {string} - 拼接成的请求字符串
 */
export function encodeSearchParams (obj) {
  const params = []

  Object.keys(obj).forEach((key) => {
    let value = obj[key]
    // 如果值为undefined我们将其置空
    if (typeof value === 'undefined') {
      value = ''
    }
    // 对于需要编码的文本（比如说中文）我们要进行编码
    params.push([key, encodeURI(value)].join('='))
  })
  return params.join('&')
}
