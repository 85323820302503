import { axios } from '@/utils/request'
import * as UtilsService from '@/utils/utilsService'
/**

 * 获取案例
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getSelectByPage (parameter) {
    return axios({
        method: 'get',
        url:'/api/case/selectByPage?' +UtilsService.encodeSearchParams(parameter)
    })
}

