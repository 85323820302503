<template>
  <div>
    <div class="block">
      <el-carousel height="calc(100vh - 78px)">
        <el-carousel-item v-for="item in bannerArr">
          <div>
            <div class="banner-content-container">
              <div class="container banner-content">
                <div class="sub-title">上能能源愿景</div>
                <h2 class="title">成为山东最具有价值</h2>
                <h2 class="title">太阳能科技公司</h2>

                <p class="description">
                 稳健、可靠、科技引领推动山东绿能行业发展
                </p>
<!--                <el-button class="redirect-button is-dark-background">了解更多</el-button>-->
              </div>
            </div>
            <div class="slide-container">
              <!--              <video src="https://static.longi.com/15s_E_8ff174a3e3.m4v#t=0.001" loop="loop" autoplay="autoplay"-->
              <!--                     muted="muted"></video>-->
              <picture class="slide-image " >
                <source media="(max-width: 768px)"
                        >
                <img alt=""
                     style="object-fit:cover;object-position:center;font-family:'object-fit: cover; object-position: center;'"
                      class=""
                     :src="item.pic"
                >
              </picture>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

  </div>
</template>

<script>
console.log(process.env.VUE_APP_IMG_URL)
let imgURL = process.env.VUE_APP_IMG_URL + 'banner/'
export default {
  name: "FirstCarousel",
  data: function () {
    return {
      bannerArr: [
        {
          pic: imgURL + '1.jpg',
        },
        {
          pic: imgURL + '2.jpg',
        },
        {
          pic: imgURL + '3.jpg',
        },
        {
          pic: imgURL + '4.jpg',
        },
      ]
    }
  }
}
</script>

<style scoped>
.block {
  color: #fff;
}
.carousel-box {
  height: calc(100vh - 78px);
  min-height: 668px;
  overflow: hidden;
}

.banner-content-container {
  position: absolute;
  top: 140px;
  z-index: 2;
  right: 0;
  left: 0;
}


.banner-content {
  margin-left: calc(50% - 640px);
}

.sub-title {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 20px;
}
.banner-content .title {
  font-size: 40px;
  margin-bottom: 20px;
}
.description {
  margin-bottom: 72px;
  max-width: 528px;
  font-size: 24px;
  line-height: 24px;
}


.slide-image {

}
.slide-container {
  position: relative;
  min-width: 1280px;
  max-width: 2048px;
  height: calc(100vh - 78px);
  min-height: 668px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .slide-container {
    min-width: auto;
    height: calc(100vh - 68px);
  }

  .banner-content-container {
    top: 76px;
  }

  .banner-content {
    margin: 0 auto;
    text-align: center;
  }

  .sub-title {
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .title {
    width: auto;
    max-width: 100%;
    font-size: 24px;
    line-height: 36px;
  }

  .description {
    width: auto;
    max-width: 100%;
    line-height: 28px;
    margin: 0 24px 32px;
  }

  .redirect-button {
    margin: 0 auto;
  }

  video {
    height: calc(100vh - 78px);
    width: 100%;
    object-fit: cover;
  }
}
</style>
