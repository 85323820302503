<template>
  <div class="news-container">
    <div class="news-content-container news">
      <a
          v-for="item in newsList"
          @click="newDetail(item)"
        class="news-item">
      <div class="image-box">
        <picture class="image">
          <source media="(max-width: 768px)"
                  >
          <img
              :src="item.coverUrl"
               alt="" class=""
              style="object-fit: cover; object-position: center center; font-family: &quot;object-fit: cover; object-position: center;&quot;;">
        </picture>
      </div>
      <div class="text-box">
        <p class="title">
          {{item.title}}</p>
        <p class="description" v-html="item.detail">
         </p>
        <div class="tag-box">
          <div class="tag">上明新闻</div>
          <span class="date">{{item.updateTime}}</span></div>
      </div>
    </a>

    </div>

  </div>
</template>

<script>
import * as newsService from "@/api/news";
import {IsPC} from "@/utils/utils";

export default {
  name: "DynamicContainerNews",
  data: function () {
    return {
      newsList: [],
      pagination: {
        current: 1,
        pageSize: 3,
        total: 100,
        end: false,
      },
    }
  },
  methods:{
    getSearchData() {
      let data = {

        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }
      return data
    },
    newDetail: function (item) {
      this.$router.push({
        path: '/newsDetail', query: {id:item.id}
      })
    },
    loadData() {

      let data = this.getSearchData()
      newsService.getSelectByPage(data).then(e => {
        if (e.status == 200) {
          let data = e.data.data
          if(data.records.length == 0) {
            //没有数据
            this.pagination.end = true;
            return
          }
          this.pagination.total = data.total
          this.pagination.pageSize = data.size
          this.pagination.current = data.current
          this.newsList = data.records

        } else {
          console.log('查询失败')
        }

      }).finally(() => {
        this.loading = false
      })
    },
  },
  created() {
    this.loadData()

  }
}
</script>

<style scoped>
.news-container {
  justify-content: space-between;
  width: 100%;
  height: 622px;
  display: flex;
  -webkit-box-pack: justify;
  margin: 42px 0 48px;
}

.news-content-container {
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.price-container {
  margin-left: 20px;
  padding: 16px 32px 17px;
  background-color: #fff;
  width: 305px;
  height: 100%;
}
.price-header {
  margin-bottom: 8px;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.price-content {
  text-align: left;
  margin: 0;
}
.price-item {
  padding: 24px 0 16px;
  border-top: 1px solid #eaeaea;
  height: 174px;
  display: grid;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-template-columns: 50% 50%;
}
.product-name {
  padding-bottom: 24px;
  grid-column-start: span 2;
  color: #545454;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
}
.unit {
  color: #545454;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
}
.price {
  color: #000;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.price-header .title {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.date {
  text-align: left;
  margin-top: 4px;
  color: #545454;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
}

.news-item:first-child {
  margin-top: 0;
}

.news-item {
  display: flex;
  margin-top: 20px;
  background-color: #fff;
}

.image-box {
  position: relative;
  width: 305px;
  height: 194px;
  overflow: hidden;
}

.image {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .4s ease-in-out
}

img {
  width: 100%;
  height: 100%;
}

.text-box {
  margin: 24px 26px 0 24px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

.title {
  text-align: left;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  display: -webkit-box;
  max-height: 56px;
  overflow: hidden;
  line-height: 28px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  -webkit-box-oriennt: vertical;
  -webkit-line-clamp: 2
}

.description {
  text-align: left;
  margin-top: 8px;
  color: #545454;
  font-weight: 300;
  display: -webkit-box;
  max-height: 48px;
  overflow: hidden;
  line-height: 24px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.tag-box {
  display: flex;
  margin-top: 12px;
}

.tag {
  color: #e60012;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  padding: 6px 12px;
  text-align: center;
  background-color: #f3f3f3;
  margin-right: 12px;
}

.date {
  color: #545454;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
}
.learn-more-button {
  display: none;
}
@media (max-width: 768px) {
  .news-container {
    margin-top: 0;
    margin-bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
  }
  .news-content-container {
    padding-right: 24px;
    padding-left: 24px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .news-item {
    background-color: #f9f9f9;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
  }
  .image-box {
    margin-top: 36px;
    width: 100%;
    height: 198px;
  }
  .text-box {
    margin: 16px 0 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .title {
    max-height: 84px;
    -webkit-line-clamp: 3;
  }
  .description {
    max-height: 72px;
    -webkit-line-clamp: 3;
  }
  .price-container {
    width: auto;
    margin: 0 24px;
  }
  .learn-more-button {
    display: block;
    padding: 0 24px;
  }
  .learn-more-mobile {
    margin: 48px auto;
    width: 100%;
    color: #000000;
  }
  .btn.is-outlined-primary {
    background-color: rgba(0,0,0,0);
    border-color: #bfbfbf;
  }

}
</style>
