<template>
  <div>
    <div class="solution-list hidden-desktop" >
      <a
          v-for="item in caseList"
          class="solution-card"
      ><img
          data-src="@/assets/P_Vsolution_095dc7e7cc.jpg" alt="" class="background-image"
          :src="item.imgUrl">
        <p class="title">{{item.title}}</p>
      </a>

    </div>
    <div class="solution-list hidden-desktop" v-if="caseList.length == 0">
      <a
          class="solution-card"
      ><img
          data-src="@/assets/P_Vsolution_095dc7e7cc.jpg" alt="" class="background-image"
          src="@/assets/P_Vsolution_095dc7e7cc.jpg">
        <p class="title">电站解决方案</p></a><a class="solution-card"
    ><img
        data-src="@/assets/homepage_solution_67a035c486.jpg" alt="" class="background-image"
        src="@/assets/homepage_solution_67a035c486.jpg">
      <p class="title">行业解决方案</p></a><a
        class="solution-card"
    ><img
        data-src="@/assets/residential_d1e20b5cb2.jpg" alt="" class="background-image"
        src="@/assets/residential_d1e20b5cb2.jpg">
      <p class="title">户用解决方案</p></a><a class="solution-card"
    ><img
        data-src="@/assets/hrdrogen_solution_cover_f54e6b67e7.jpg" alt="" class="background-image"
        src="@/assets/hrdrogen_solution_cover_f54e6b67e7.jpg">
      <p class="title">氢能方案</p></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionListMobile",
  props: ['caseList']
}
</script>

<style scoped>
.solution-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.solution-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  height: 380px;
  color: #fff;
  text-decoration: none;
  text-align: center;
}

.solution-card:before {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 141px;
  opacity: .6;
  content: "";
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), #264684);
  transform: rotate(-180deg);
}

.background-image {
  position: absolute;
  top: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.title {
  width: 85%;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 60px 0 36px;
}

@media (max-width: 768px) {

}
</style>