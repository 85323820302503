<template>
  <div class="solution-list hidden-mobile">

    <div class="solution-card" v-for="item in caseList">
      <picture class="background-image">
        <source media="(max-width: 768px)"
        >
        <!---->
        <img alt=""
             style="object-fit:cover;object-position:center;font-family:'object-fit: cover; object-position: center;'"
             class="" :src="item.imgUrl">
      </picture>
      <div class="mask"></div>
      <div class="top-progress-back"></div>
      <div class="top-progress"></div>
      <div class="title-mask"></div>
      <p class="title">{{item.title}}</p> <img data-src="@/assets/_671205fb3b.png"
                                             alt="" class="icon"
                                             src="@/assets/_671205fb3b.png">
      <p class="description">{{item.address}}</p>

    </div>

    <div class="solution-card" v-if="caseList.length == 0">
      <picture class="background-image">
        <source media="(max-width: 768px)"
        >
        <!---->
        <img alt=""
             style="object-fit:cover;object-position:center;font-family:'object-fit: cover; object-position: center;'"
             class="" src="@/assets/P_Vsolution_095dc7e7cc.jpg">
      </picture>
      <div class="mask"></div>
      <div class="top-progress-back"></div>
      <div class="top-progress"></div>
      <div class="title-mask"></div>
      <p class="title">电站解决方案</p> <img data-src="@/assets/_671205fb3b.png"
                                             alt="" class="icon"
                                             src="@/assets/_671205fb3b.png">
      <p class="description">助力电力行业全面实现零排</p> <!----> </div>
    <div class="solution-card" v-if="caseList.length == 0">
      <picture class="background-image">
        <source media="(max-width: 768px)"
        >
        <img  alt=""
              style="object-fit:cover;object-position:center;font-family:'object-fit: cover; object-position: center;'"
              class="" src="@/assets/homepage_solution_67a035c486.jpg"></picture>
      <div class="mask"></div>
      <div class="top-progress-back"></div>
      <div class="top-progress"></div>
      <div class="title-mask"></div>
      <p class="title">行业解决方案</p> <img data-src="@/assets/_83199a15be.png"
                                             alt="上明工厂图ICON" class="icon"
                                             src="@/assets/_83199a15be.png">
      <p class="description">光伏+行业的全场景方案</p> <!----></div>
    <div class="solution-card" v-if="caseList.length == 0">
      <picture class="background-image">
        <source media="(max-width: 768px)"
        >
        <img  alt=""
              style="object-fit:cover;object-position:center;font-family:'object-fit: cover; object-position: center;'"
              class="" src="@/assets/residential_d1e20b5cb2.jpg"></picture>
      <div class="mask"></div>
      <div class="top-progress-back"></div>
      <div class="top-progress"></div>
      <div class="title-mask"></div>
      <p class="title">户用解决方案</p> <img data-src="@/assets/_20bd58baa8.png"
                                             alt="" class="icon"
                                             src="@/assets/_20bd58baa8.png">
      <p class="description">上明分布式助力建设美丽乡村</p> <!----> </div>
    <div class="solution-card" v-if="caseList.length == 0">
      <picture class="background-image"><!----> <img
          data-src="@/assets/hrdrogen_solution_cover_f54e6b67e7.jpg" alt=""
          style="object-fit:cover;object-position:center;font-family:'object-fit: cover; object-position: center;'"
          class="" src="@/assets/hrdrogen_solution_cover_f54e6b67e7.jpg">
      </picture>
      <div class="mask"></div>
      <div class="top-progress-back"></div>
      <div class="top-progress"></div>
      <div class="title-mask"></div>
      <p class="title">氢能方案</p> <img data-src="@/assets/_eea6a02af6.png"
                                         alt="" class="icon"
                                         src="@/assets/_eea6a02af6.png">
      <p class="description">绿电+绿氢创建可持续的零碳基础设施</p> <!----> </div>
  </div>
</template>

<script>
import * as caseService from "@/api/cases";
import {IsPC} from "@/utils/utils";

export default {
  name: "SolutionList",
  props:['caseList']
}
</script>

<style scoped>
.solution-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.solution-card:not(:last-child) {
  margin-right: 20px;
}

.solution-card {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 305px;
  min-height: 614px;
  overflow: hidden;
  color: #fff;
}
.background-image {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.solution-card {
  text-align: center;
}

.mask {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  background: linear-gradient(180deg,rgba(16,30,55,.5),#0b1b39);
}
.top-progress-back {
  background: #333e5c;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 4px;
}
.top-progress {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 0;
  height: 4px;
  transition: width .3s ease-in-out;
  background: #d42d26;
}
.title-mask {
  left: 0;
  background: linear-gradient(180deg,rgba(0,0,0,0),#264684);
  transform: rotate(-180deg);
  position: absolute;
  top: 0;
  width: 100%;
  height: 232px;
  opacity: .6;
}
.title {
  margin: 60px 0 36px;
  position: relative;
  z-index: 1;
  width: 85%;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px
}
.icon {
  position: relative;
  z-index: 1;
  width: 209px;
  height: 134px;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;
  margin-bottom: 24px;
  background-repeat: no-repeat;
  background-position: 50%;
}
.description {
  text-align: center;
  position: relative;
  z-index: 1;
  width: 75%;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;
}
.link-button {
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 96px;
  transition: all .3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
.btn-icon {
  margin-left: 12px
}
.solution-card:hover .mask {
  visibility: visible;
  opacity: 1;
}
.solution-card:hover .icon{
  visibility: visible;
  opacity: 1;
}
.solution-card:hover .description {
  visibility: visible;
  opacity: 1;
}
.solution-card:hover .link-button {
  transform: translateY(0);
  z-index: auto;
  display: flex;
  visibility: visible;
  opacity: 1;
}
.solution-card:hover .top-progress {
  width: 100%;
}

</style>
