<template>
  <div>
    <div class="introduction min-container">
      <h2 class="title" style="padding: 0;">上明光伏产业链</h2>
      <div class="mark-bar-content title-divide-line bar-primary"></div>
      <p class="text">
        上明坚持以客户价值为核心，致力于为全人类创造美好宜居的绿色家园。通过提供高附加值的产品为绿能行业提供一站式绿能解决方案，让您的选择更高效、服务更便捷。
      </p>
    </div>
    <div class="">
      <div class="web-card-group hidden-mobile min-container">
        <div
            v-for="(item, i) in productsInfo"
            @mouseenter="expandItem($event)"
             :data-index="i"
             class="item web-card-item "
             :class="{'item-expand':showExpandIndex == i}">
          <div class="header">
            <h3 class="title">{{item.title}}</h3>
            <img alt="LONGi grey logo" class="logo" src="@/assets/logo.png">
          </div>
          <div class="hover-point">
          </div>
          <div class="intro">
            <img  alt="" class="img"
                  v-for="imgSrc in item.img"
                 :src="imgSrc">
          </div>
<!--          <el-button class="is-primary learn-more" v-show="showExpandIndex == i">了解更多</el-button>-->
        </div>

        <div class="cross-line"></div>
      </div>
    </div>
    <industry-intro-mobile></industry-intro-mobile>
  </div>

</template>

<script>
import IndustryIntroMobile from "@/views/body/IndustryIntroMobile";

export default {
  name: "IndustryIntro",
  components: {
    IndustryIntroMobile
  },
  data: function () {
    return {
      productsInfo:[
        {
          title:'逆变器合作伙伴',
          img:[
              'http://www.smsunpower.com/upload/other/nbq1.png',
              'http://www.smsunpower.com/upload/other/nbq2.png',
          ],
          info:'硅片规格尺寸与现有PERC电池设备匹配度高、兼容性强，符合降低度电成本；适应“硅片大尺寸化”发展趋势，且具有高性价比。',
          link:'',
        },
        {
          title:'支架合作伙伴',
          img:[
            'http://www.smsunpower.com/upload/other/zj1.png',
            'http://www.smsunpower.com/upload/other/zj2.png',
          ],
          info:'硅片规格尺寸与现有PERC电池设备匹配度高、兼容性强，符合降低度电成本；适应“硅片大尺寸化”发展趋势，且具有高性价比。',
          link:'',
        },
        {
          title:'线缆合作伙伴',
          img:[

            'http://www.smsunpower.com/upload/other/xl1.png',
            'http://www.smsunpower.com/upload/other/xl2.png',
          ],
          info:'硅片规格尺寸与现有PERC电池设备匹配度高、兼容性强，符合降低度电成本；适应“硅片大尺寸化”发展趋势，且具有高性价比。',
          link:'',
        },
      ],
      showExpandIndex:0,
    }
  },
  methods: {
    expandItem: function (e) {
      let i = e.target.dataset.index
      this.showExpandIndex = i
    },

  },
}
</script>

<style scoped>
.introduction {
  margin: 70px auto 36px;
}

.min-container {
  width: 1280px;
  margin-right: auto;
  margin-left: auto;
}

.title {
  padding: 0 48px;
  overflow-x: hidden;
  color: #131313;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin: 0;
}

.mark-bar-content {
  border-top-width: 8px;
  border-top-style: solid;
  width: 52px;
  height: 0;
  border-right: 6px solid rgba(0, 0, 0, 0);
}

.title-divide-line {
  margin-top: 28px;
}

.bar-primary {
  border-top-color: #e60012;
}

.text {
  width: 887px;
  color: #545454;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}


.card-group .item .title {
  margin: 0;
  overflow-x: hidden;
  color: #131313;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  white-space: nowrap;
  padding: 0 48px;

}

.web-card-group {
  position: relative;
  margin-bottom: 98px;
  display: flex;
}

.item {
  position: relative;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 305px;
  overflow: hidden;
  -webkit-transition: width .3s linear;
  -o-transition: width .3s linear;
  transition: width .3s linear;
}

.item-expand {
  width: 630px;
}

.web-card-item {
  background-color: #f9f9f9;
}

.header {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-expand .header {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 32px 0 48px;
}

.item-expand .title {
  padding: 0;
  animation: fade-data-v-da90fcd0 .6s linear;
}

.item-expand .logo {
  display: block;
}

.logo {
  display: none;
  height: 24px;
  width: auto;
}

.hover-point {
  position: relative;
  z-index: 3;
  width: 12px;
  height: 12px;
  margin: 16px auto 0;
  background-color: #eaeaea;
  border-radius: 50%;
}

.item-expand .hover-point {
  margin: 16px 0 0 48px;
  background-color: #e60012;
}

.intro {
  display: flex;
  justify-content: start;
  flex-direction: column;
  height: 268px;
  margin: 4px 12.5px 36px;
}

.img {
  width: 280px;
  height: 80px;
  margin-bottom: 20px;
}

.web-card-item:nth-child(2) {
  margin: 0 20px;
}

.item-expand .intro-text {
  animation: fade-data-v-da90fcd0 .6s linear;
  display: block;
  right: 32px;
}

.intro-text {
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  display: none;
  width: 306px;
  color: #545454;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
}

.cross-line {
  position: absolute;
  top: 90px;
  z-index: 2;
  width: 1184px;
  height: 1px;
  background-repeat: repeat-x;
  background-size: 10px 1px;
  background-image: linear-gradient(90deg, #bfbfbf, #bfbfbf 50%, rgba(0, 0, 0, 0) 0);
  left: 48px;
}

.learn-more {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 36px;
  white-space: nowrap;
}

.is-primary {
  background-color: #e60012;
  border-color: #e60012;
  color: #fff;
}
.item-expand .intro-text {
  animation:  fade-data .6s linear;
}
.item-expand .title {
  animation:  fade-data .6s linear;
}
@media (max-width: 768px) {
  .introduction {
    margin-top: 0;
  }

  .min-container {
    width: auto;
  }

  .title {
    margin: 64px 24px 12px;
    text-align: center;
    font-size: 28px;
    line-height: 44px;
  }

  .title-divide-line {
    margin: 0 auto;
  }

  .text {
    width: auto;
  }
}


</style>
