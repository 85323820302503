<template>
  <div class="hidden-desktop">
    <van-swipe class="my-swipe"  indicator-color="#000">
      <van-swipe-item v-for="item in imgs">
        <div class="mobile-tech-show-total">
          <div class="icon-container" >
            <img data-src="https://static.longi.com/_fb33a30726.png" alt=""
                 class="show-icon"
                 src="@/assets/_fb33a30726.png">
          </div>
          <h3 class="description-title" >上明能源工厂</h3>
          <p class="description-content" >
            济南上明能源科技有限公司控股山东上明晶硅新能源有限公司，山东上明晶硅新能源有限公司专业从事光伏组件生产及销售，经过公司工程师及研发人员的不断创新，公司已经具备年产能800MW的生产能力，组件质量优秀，已经报备金太阳及TUV等相关认证。公司与英利、山东德建、大海、力诺等企业长期合作。
          </p>
          <div class="image-container" >
            <img
                 class="show-image"
                :src="item">
          </div>
          <div class="show-more-button">
<!--            <el-button class="is-primary" style="width: 100%;">了解更多</el-button>-->
          </div>
        </div>
      </van-swipe-item>

    </van-swipe>

  </div>
</template>

<script>
export default {
  name: "TechnologyContainerMobile",
  data: function () {
    return {
      imgs: [
        'http://www.smsunpower.com/upload/other/gc1.jpg',
        'http://www.smsunpower.com/upload/other/gc2.jpg',
          'http://www.smsunpower.com/upload/other/gc3.jpg',
      ]
    }
  }
}
</script>

<style scoped>
.mobile-tech-show-total {
  width: 100%;
}
.icon-container {
  margin: 0 auto;
  width: 45px;
  height: 45px;
}
.show-icon {
  width: 100%;
  height: 100%;
}
.description-title {
  margin: 22px 24px 0;
  text-align: center;
  color: #131313;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.description-content {
  max-height: 192px;
  overflow: hidden;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin: 24px 24px 0;
  text-align: center;
}
.image-container {
  margin: 40px auto 0;
  padding: 0 24px;
  width: 100%;
  height: 178px;
}
.show-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.show-more-button {
  margin: 48px auto 24px;
  padding: 0 24px;
  text-align: center;
  height: 100%;
}
</style>
