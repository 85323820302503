import {axios} from "@/utils/request";
import * as UtilsService from "@/utils/utilsService";

/**

 * 获取产品
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getSelectByPage (parameter) {
    return axios({
        method: 'get',
        url:'/api/product/selectByPage?' +UtilsService.encodeSearchParams(parameter)
    })
}
//根据id获取
export function getSelectById (parameter) {
    console.log(parameter)
    return axios({
        method: 'get',
        url:'/api/product/selectById/' +parameter
    },)
}
