<template>
  <div class="technology-container fullscreen-container">
    <div class="hidden-mobile">
      <el-carousel height="360px">
        <el-carousel-item v-for="item in imgs" :key="item">
          <div class="tech-show-total">
            <div class="total-item">
              <img
                  alt=""
                  class="icon" src="@/assets/_fb33a30726.png">
              <div class="description"><h3 class="description-title">上明能源工厂</h3>
                <p class="description-content">济南上明能源科技有限公司控股山东上明晶硅新能源有限公司，山东上明晶硅新能源有限公司专业从事光伏组件生产及销售，经过公司工程师及研发人员的不断创新，公司已经具备年产能800MW的生产能力，组件质量优秀，已经报备金太阳及TUV等相关认证。公司与英利、山东德建、大海、力诺等企业长期合作。</p>
              </div>
              <div class="more-and-scroll">
                <div class="show-more-button">
<!--                  <el-button class="is-primary">了解更多</el-button>-->
                </div>
                <div class="scroll-bar">
                  <div class="progress-container">
                    <div class="progress"></div>
                  </div>
                  <div class="progress-container">
                    <div class="progress"></div>
                  </div>
                  <div class="progress-container active">
                    <div class="progress"></div>
                  </div>
                </div>
              </div>
            </div>
            <img  alt=""
                 class="total-item show-image" :src="item">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <technology-container-mobile></technology-container-mobile>
<!--    <div class="research-list">-->
<!--      <div class="card">-->
<!--        <div class="card-data"><span class="card-number">1000</span> <span-->
<!--            class="card-unit">&lt;+&gt;</span></div>-->
<!--        <span class="card-title">研发人员</span></div>-->
<!--      <div class="card">-->
<!--        <div class="card-data"><span class="card-number">￥123.58</span> <span-->
<!--            class="card-unit">&lt;亿元&gt;</span></div>-->
<!--        <span class="card-title">研发投入</span></div>-->
<!--      <div class="card">-->
<!--        <div class="card-data"><span class="card-number">1,387</span> <span-->
<!--            class="card-unit">&lt;项&gt;</span></div>-->
<!--        <span class="card-title">专利数量</span></div>-->
<!--      <div class="card">-->
<!--        <div class="card-data"><span class="card-number">26.50</span> <span-->
<!--            class="card-unit">&lt;%&gt;</span></div>-->
<!--        <span class="card-title">HJT 转化效率</span></div>-->
<!--    </div>-->
  </div>
</template>

<script>
import TechnologyContainerMobile from "@/views/body/TechnologyContainerMobile";
export default {
  name: "TechnologyContainer",
  components:{
    TechnologyContainerMobile
  },
  data:function () {
    return {
      imgs:[
          'http://www.smsunpower.com/upload/other/gc1.jpg',
          'http://www.smsunpower.com/upload/other/gc2.jpg',
          'http://www.smsunpower.com/upload/other/gc3.jpg',
      ]
    }
  }
}
</script>

<style scoped>
.technology-container {
  padding: 120px 0 68px;
  background-color: #f9f9f9;
  margin-right: auto;
  margin-left: auto;
  min-width: 1280px;
  max-width: 2048px;
}

.tech-show-total {
  display: flex;
  flex-direction: row;
  width: 1280px;
  height: 360px;
  overflow-x: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  margin: 0 auto;
}

.total-item {
  position: relative;
  width: 50%;
  height: 100%;
}

.icon {
  margin: 0 auto;
  width: 45px;
  height: 45px;
}

.description {
  margin: 0;
  width: 550px;
  height: 120px;

}

.description-title {
  margin: 16px auto;
  height: 24px;
  color: #131313;
  font-weight: 600;
  font-size: 24px;
}

.description-content {
  width: 542px;
  height: 116px;
  overflow: hidden;
  color: #545454;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.more-and-scroll {
  position: absolute;
  top: 258px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  left: 0;
  margin-right: 98px;
}

.show-image {
  width: 640px;
  height: 360px;
  object-fit: cover;
}

.research-list {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  width: 1280px
}

.card {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  width: 25%;
  min-height: 132px;
  padding: 24px 0 24px 32px;
  background-color: #fff;
}

.card-data {
  margin-bottom: 12px;
  display: flex;
}

.card-number {
  margin-right: 8px;
  max-width: 70%;
  color: #131313;
  font-weight: 800;
  font-size: 36px;
  line-height: 54px;
}

.card-unit {
  max-width: 30%;
  color: #545454;
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
}

.card-title {
  color: #545454;
  font-size: 14px;
  line-height: 14px;
}

@media (max-width: 768px) {
  .fullscreen-container {
    min-width: auto;
    padding: 72px 0 0;
  }

  .research-list {
    width: 100%;
    min-width: 360px;
  }

  .card {
    border: none;
    padding: 0 12px;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .card-data {
    justify-content: center;
    width: 100%;
    align-items: center;
    -webkit-box-pack: center;
    flex-direction: column;
    -webkit-box-align: center;
    margin-bottom: 16px;
  }

  .card-number {
    text-align: center;
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
    font-size: 28px;
    line-height: 44px;
  }

  .card-unit {
    margin-top: 0;
    text-align: center;
  }

  .card-title {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }


}
</style>
