import { axios } from '@/utils/request'
import * as UtilsService from '@/utils/utilsService'
/**

 * 获取新闻
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getSelectByPage (parameter) {
    return axios({
        method: 'get',
        url:'/api/news/selectByPage?' +UtilsService.encodeSearchParams(parameter)
    })
}
//根据id获取
export function getSelectById (parameter) {
    console.log(parameter)
    return axios({
        method: 'get',
        url:'/api/news/selectById/' +parameter
    },)
}
