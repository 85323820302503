<template>
  <header v-show="headerShowStatus">
    <div class="container">
      <div class="content">
        <div class="total-container">
          <a>
            <router-link to="/">
              <img src="@/assets/logo.png" class="nav-icon" alt="">
            </router-link>
          </a>
          <div class="nav-title-container hidden-mobile" @mouseleave.stop="clearTap($event)">
            <div class="nav-title">
              <div class="nav-title-item">
                <div v-for="(item, i) in headerInfo" :data-index="i" class="name-container"
                     @mouseenter="enterTap($event)"
                     @mouseleave="leaveChange($event)"
                >
                  <p class="nav-title-name" :data-index="i" :class="{'name-under':tapActive(i)}">{{ item.name }}</p>
                </div>
                <div v-for="(item, i) in headerInfoDetail">
                  <transition name="el-fade-in-linear">
                    <div v-show="tapIndex == i">
                      <div class="nav-drop-down">
                        <div class="dropdown-container">
                          <div class="secondary-nav">
                            <div class="secondary-nav-item" v-for="(navInfo, i) in item.items">
                              <div class="secondary-container">
                                <div class="name-container" v-if="navInfo.title">
                                  <div class="secondary-nav-name">{{ navInfo.title }}</div>
                                </div>
                                <p class="secondary-nav-description">{{ navInfo.titleInfo }}</p>
                                <div class="divide-line"></div>
                                <ul class="nav-link-group list-unstyled">
                                  <li class="nav-link" v-for="info in navInfo.items">
                                    <a @click="goPage(info.link)"
                                       class="nav-link-a">
                                      {{ info.name }}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>

                          </div>
                          <a  class="image-container clickable">
                            <img
                               alt="" class="image"
                              src="@/assets/RQcode.jpg"></a></div>
                      </div>
                      <div class="mask" @mouseover="leaveTap()"></div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>

          <div class="hidden-desktop">

            <i class="el-icon-menu" @click="showPopup" v-show="!showDropdown"></i>
            <i class="el-icon-close" @click="showPopup" v-show="showDropdown"></i>

            <head-mobile-tap :show-dropdown="showDropdown" @changeShowListVal="changeShowListVal"></head-mobile-tap>
          </div>

        </div>
        <!--        <div class="header-right-container hidden-mobile">-->
        <!--          <div class="search-container">-->
        <!--            <div class="icon-container">-->
        <!--              <i class="el-icon-search"></i>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="navbar-container hidden-mobile">-->
        <!--            <div data-v-244ecb12="" id="change-language-button" class="navbar-link">-->
        <!--              <img data-v-244ecb12=""-->
        <!--                   src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iOS41IiBzdHJva2U9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0xNy41IDEyQzE3LjUgMTQuNjg2NCAxNi44NDU2IDE3LjA5NDIgMTUuODEzOSAxOC44MTM4QzE0Ljc3NjIgMjAuNTQzNCAxMy40MTIyIDIxLjUgMTIgMjEuNUMxMC41ODc4IDIxLjUgOS4yMjM4MyAyMC41NDM0IDguMTg2MTEgMTguODEzOEM3LjE1NDM2IDE3LjA5NDIgNi41IDE0LjY4NjQgNi41IDEyQzYuNSA5LjMxMzYyIDcuMTU0MzYgNi45MDU3NiA4LjE4NjExIDUuMTg2MThDOS4yMjM4MyAzLjQ1NjY1IDEwLjU4NzggMi41IDEyIDIuNUMxMy40MTIyIDIuNSAxNC43NzYyIDMuNDU2NjUgMTUuODEzOSA1LjE4NjE4QzE2Ljg0NTYgNi45MDU3NiAxNy41IDkuMzEzNjIgMTcuNSAxMloiIHN0cm9rZT0iYmxhY2siLz4KPHJlY3QgeD0iMi4yNSIgeT0iMTIuMjUiIHdpZHRoPSIxOS41IiBoZWlnaHQ9IjAuNSIgZmlsbD0iYmxhY2siIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMC41Ii8+CjxyZWN0IHg9IjExLjc1IiB5PSIyMS43NSIgd2lkdGg9IjE5LjUiIGhlaWdodD0iMC41IiB0cmFuc2Zvcm09InJvdGF0ZSgtOTAgMTEuNzUgMjEuNzUpIiBmaWxsPSJibGFjayIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KPC9zdmc+Cg=="-->
        <!--                   alt="language"-->
        <!--                   class="language-image">-->
        <!--              <span-->
        <!--                  data-v-244ecb12="" class="language-name">简体中文</span>-->
        <!--            </div>-->

        <!--          </div>-->
        <!--          <el-button class="login">登录</el-button>-->

        <!--        </div>-->
      </div>

    </div>
  </header>
</template>

<script>
import HeadMobileTap from "@/views/body/HeadMobileTap";
import * as productSever from "@/api/product/index";
import {IsPC} from "@/utils/utils";
export default {
  name: "Head",
  components: {
    HeadMobileTap
  },
  data: function () {
    return {
      headerShowStatus: true,
      showDropdown: false,
      tapIndex: -1,
      headerInfo: [
        {
          name: '产品',
        },
        {
          name: '新闻',
        },
        {
          name: '成功案例',
        },
        {
          name: '关于上明',
        },
      ],
      headerInfoDetail: [
        {
          items: [
            {
              title: '',
              titleInfo: '单晶片硅',
              items: [
                {
                  name: '单晶144片182组件',
                  link: 'productDetail'
                },
                {
                  name: '单晶硅132片182组件',
                  link: 'productDetail'
                },
              ]
            }
          ]
        },
        {
          items: [
            {
              title: '',
              titleInfo: '新闻',
              items: [
                {
                  name: '上明新闻',
                  link:'news'
                },

              ]
            }
          ]
        },

        {
          items: [
            {
              titleInfo: '成功案例',
              title: '',
              items: [
                {
                  name: '案例合集',
                  link: 'cases'
                },
              ]
            }
          ]
        },
        {
          items: [
            {
              titleInfo: '专注技术创新',
              title: '观上明发展',
              items: [
                {
                  name: '公司简介',
                  link:'/about',
                },
                {
                  name: '公司资质',
                  link:'/about/info',
                },
              ]
            },
            {
              titleInfo: '共建可持续发展的绿色供应链生态',
              title: '联系上明',
              items: [
                {
                  name: '联系方式',
                  link:'/link'
                },

              ]
            },

          ]
        }

      ]
    }
  },
  mounted() {
    //监听滚轮事件
    window.addEventListener('mousewheel', this.showHideHeader);
  },
  created() {
    this.getProduct()
  },
  methods: {
    getProduct() {
      let data = {
        pageNo: 1,
        pageSize: 10
      }
      productSever.getSelectByPage(data).then(e=>{
            if (e.status == 200) {
              let data = e.data.data.records
              let arr = []
              for(let item of data){
                let obj = {
                  name: item.title,
                  link:'productDetail?id='+item.id
                }
                arr.push(obj)
              }
              this.headerInfoDetail[0].items[0].items = arr
            }
      })
    },
    changeShowListVal(val){
      this.showDropdown = val
    },
    goPage(path){

      this.$router.push(path)

      this.clearTap()
    },
    showHideHeader(e) {
      let direction = e.deltaY > 0
      if (!direction) {
        this.headerShowStatus = true
      } else {
        this.headerShowStatus = false
        this.leaveTap()
      }
    },
    showPopup() {
      this.showDropdown = !this.showDropdown;
    },
    clearTap(e) {
      this.tapIndex = -1
    },
    enterTap(e) {
      let index = e.target.dataset.index
      this.tapIndex = index;
    },
    leaveChange() {
      this.leaveTapCheck = true;
    },
    tapActive(index) {
      return this.tapIndex == index
    },
    leaveTap() {
      this.tapIndex = -1
    }
  }
}
</script>

<style scoped>
header {
  margin: 0 auto;
  border-bottom: 1px solid #eaeaea;
  transition: all .3s ease-in-out;
  position: fixed;
  top: 0;
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  max-width: 2048px;
  height: 78px;
}

.container {
  right: 0;
  left: 0;
  margin: 0 auto;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  min-width: 1280px;
  height: 100%;
}

.content {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
  height: 100%;
}

.total-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding-right: 15px;
  padding-left: 30px;
}

.nav-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  list-style-type: none
}

.nav-title:first-of-type {
  padding-left: 52px;
}

.nav-title-container {
  display: inline-block;
}

.nav-title-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 78px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  cursor: default;
}

.name-container {
  padding: 0 20px;
  cursor: pointer
}

.nav-title-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 78px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
}

.header-right-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.icon-container {
  background-color: rgba(0, 0, 0, .001);
  cursor: pointer;
  margin-right: 24px;
}

.nav-icon {
  display: inline-block;
  width: 140px;
  height: 20px;
}

.login {
  margin-right: 0;
  margin-left: 48px;
  padding: 9px 33px;
  border: 1px solid #bfbfbf;
  cursor: pointer;
}

.name-under {
  border-bottom: 2px solid #e60012;
}

.nav-drop-down {
  right: 0;
  left: 0;
  padding-bottom: 68px;
  background-color: #fff;
  position: absolute;
  top: 78px;
  z-index: 10;
  width: 100%;
}

.mask {
  right: 0;
  left: 0;
  background-color: #000;
  position: fixed;
  top: 120px;
  bottom: 0;
  z-index: 5;
  opacity: .2;
}

.dropdown-container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1280px;
}

.secondary-nav {
  padding-right: 40px;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 1000px;
}

.secondary-nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

.secondary-container {
  margin-top: 48px;
  min-width: 210px;
}

.secondary-container .name-container {
  padding: 0;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.secondary-nav-description {
  margin-top: 4px;
  max-width: 192px;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
}

.divide-line {
  border-bottom: 1px solid #eaeaea;
  width: 210px;
  height: 12px;
}

.nav-link-group {
  padding: 0;
}

.nav-link {
  margin: 16px 0;
}

.nav-link-a {
  max-width: 210px;
  color: #545454;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
}

.secondary-nav-name {
  display: inline-block;
  max-width: 180px;
  color: #000;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.arrow {
  margin-left: 12px;
}

.image {
  margin-top: 48px;
  width: 280px;
  height: 236px;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 768px) {
  .header {
    height: 68px;
  }

  .container {
    width: 100%;
    min-width: auto;
  }

  .total-container {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding-right: 15px;
    padding-left: 30px
  }
}

</style>
