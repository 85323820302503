<template>
  <div class="mobile-card-group hidden-desktop">
    <van-swipe class="my-swipe"  indicator-color="#000">
      <van-swipe-item v-for="item in productsInfo">
        <div class="current-slide fade show-current-slide">
          <div class="card-group">
            <h2 class="title">
              {{item.title}}</h2>
            <div class="divide">
              <div class="hover-point"></div>
              <div class="cross-line"></div>
            </div>
<!--            <img alt="" class="img"-->
<!--                 :src="item.img">-->
            <img  alt="" class="img"
                  v-for="imgSrc in item.img"
                  :src="imgSrc">
            <div>
<!--              <el-button class="is-primary learn-more">了解更多</el-button>-->

            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>


  </div>
</template>

<script>
export default {
  name: "IndustryIntroMobile",
  data: function () {
    return {
      productsInfo:[
        {
          title:'逆变器合作伙伴',
          img:[
            'http://www.smsunpower.com/upload/other/nbq1.png',
            'http://www.smsunpower.com/upload/other/nbq2.png',
          ],
          info:'硅片规格尺寸与现有PERC电池设备匹配度高、兼容性强，符合降低度电成本；适应“硅片大尺寸化”发展趋势，且具有高性价比。',
          link:'',
        },
        {
          title:'支架合作伙伴',
          img:[
            'http://www.smsunpower.com/upload/other/zj1.png',
            'http://www.smsunpower.com/upload/other/zj2.png',
          ],
          info:'硅片规格尺寸与现有PERC电池设备匹配度高、兼容性强，符合降低度电成本；适应“硅片大尺寸化”发展趋势，且具有高性价比。',
          link:'',
        },
        {
          title:'线缆合作伙伴',
          img:[
            'http://www.smsunpower.com/upload/other/xl1.png',
            'http://www.smsunpower.com/upload/other/xl2.png',
          ],
          info:'硅片规格尺寸与现有PERC电池设备匹配度高、兼容性强，符合降低度电成本；适应“硅片大尺寸化”发展趋势，且具有高性价比。',
          link:'',
        },
      ],
    }
  }
}
</script>

<style scoped>
.divide {
  justify-content: left;
  display: flex;
  margin: 28px 28px 0;
  align-items: center;
}

.hover-point {
  background-color: #e60012;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.cross-line {
  background-repeat: repeat-x;
  background-size: 10px 1px;
  background-image: linear-gradient(90deg, #bfbfbf, #bfbfbf 50%, rgba(0, 0, 0, 0) 0);
  width: 100%;
  height: 1px;
}

.img {
  width: 100%;
  max-width: 280px;
  height: 80px;
  margin-bottom: 10px;
}

.intro-text {
  padding: 0 20px;
  color: #545454;
  font-weight: 400;
}

.learn-more {
  margin: 28px auto 92px;
}
.round-container {
  text-align: center;
}
@media (max-width: 768px) {

  .card-group {
    padding-top: 36px;
    text-align: center;
    background-color: #f9f9f9;
    width: 100%;
    height: 400px;
  }

  .card-group .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin-left: 28px;
    text-align: left;
    color: #000000;
  }
  .progress-container.progress-round-container {
    margin: 0 10px;
    background-color: #131313;
    border: 1px solid #131313;
    border-radius: 50%;
  }
}

.mobile-card-group {
  margin: 0 auto 80px;
  padding: 0 24px;
  position: relative;
  width: 100%;
}

.current-slide {
  display: none;
}

.show-current-slide {
  display: block;
}

</style>
